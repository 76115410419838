import firebase from "firebase/app"
// Required for side-effects
import "firebase/firestore"
import firebaseAuth from '@/hooks/firebase-auth'

import FIREBASE_CONFIG from "./.env.firebase"

// initialize firebase, this is directly from the firebase documentation
// regarding getting started for the web
if (firebase.apps.length === 0) {
  firebase.initializeApp(FIREBASE_CONFIG)
}

// const USERS_COLLECTION_PATH = "users/"
const uid: any = firebase.auth().currentUser?.uid
const currentUserData: any = {
  uid: firebaseAuth().userData.value.uid,
  name: firebaseAuth().userData.value.name,
  email: firebaseAuth().userData.value.email,
}

export default function() {
  const getAccountSettings = async () => {
    const result = { status:0, message: 'Get Account Detail Failed', data: {}}
    const query  = await firebase
      .firestore()
      .collection('users')
      .doc(uid)
      .get()
  
    // console.log(`Get Account Settings`)
    // console.log(query)
    if (query.exists) {
      result.status = 1
      result.message = 'Get Account Detail Success'
      // console.log(query.data())
      Object.assign(result.data, query.data())
    }
    return result
  }

  const getBookLists = async () => {
    const result = {status: 0, message: 'Get Books Failed', data: []}
    const queryBookLists  = await firebase
      .firestore()
      .collection("bookLists")
      .where('hasAccess', 'array-contains', uid)
      .orderBy('createdAt', 'desc')
      .get()
  
    // console.log(uid)
    // console.log(queryBookLists.docs)
    if (queryBookLists.docs.length) {
      result.status = 1
      result.message = 'Get Book Success'
      const bookList: any = queryBookLists.docs.map( (item) => {
        return {
          id: item.id,
          ...item.data()
        }
      })
      result.data = bookList
    }
    return result
  }

  const createBooks = async (newBookName: string) => {
    const queryAccount  = await firebase
      .firestore()
      .collection('users')
      .doc(uid)
      .get()

    const queryBooks  = await firebase
      .firestore()
      .collection('bookLists')
      .where('hasAccess', 'array-contains', uid)
      .get()

    // console.log(queryBooks.docs)
    // console.log(queryBooks.docs.length)
    const queryAccountData: any = queryAccount.data()
    // console.log(queryAccountData)
    const queryBooksData: any = queryBooks.docs

    let maxAllowedBooks = 2
    switch (Number(queryAccountData.accountType)) {
      case 2:
        maxAllowedBooks = 10
        break
      case 3:
        maxAllowedBooks = 99
        break
      default:
        maxAllowedBooks = 2
        break
    }
    const result = {status: 0, message: 'Create Books Failed'}
    // // console.log(queryBooksData.list.length)
    // // console.log(queryBooks.exists)

    // const now: number = new Date().getTime()
    // // // console.log(bookId)
    const postData = {
      name: newBookName.split(/ /g).map((val: any) => val[0].toUpperCase() + val.slice(1)).join(' '),
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      hasAccess: [uid],
      hasAccessData: [{
        ...currentUserData,
        grantedAt: new Date(),
      }],
      settings: {
        categories: {
          income: [
            'Sallary',
            'Saving',
            'Investment',
            'Bonus'
          ],
          expense: [
            'Food',
            'Home',
            'Bill',
            'Education'
          ]
        }
      },
      createdBy: currentUserData
    }
    // console.log(postData)
  
    if (queryBooksData.length < maxAllowedBooks) {
      const createList = await firebase
        .firestore()
        .collection('bookLists')
        .add( postData )
      // console.log(createList)
      // console.log(createList.id)
      Object.assign(result, {status: 1, message: 'Create Books Success', data: createList.id})
      // console.log(`Create Books Success`)
      if (queryBooksData.length == 0) {
        await firebase
            .firestore()
            .collection("users")
            .doc(uid)
            .update({
              'settings.defaultBook': createList.id,
              updatedAt: firebase.firestore.FieldValue.serverTimestamp()
            })
      }  
    } else {
      // alert('maxallowedbooks')
      Object.assign(result, {status: 0, message: 'Max Books Reach. Please Upgrade your account', data: ''})
    }
    return result
  }

  const updateBookDetail = async (action: string, item: any) => {
    // console.log(newItem)
    // console.log(prevItem)
    // console.log(item.id)
    // console.log(item.id)
    const result = {status: 0, message: 'Update Books Failed'}
    switch (action) {
      case 'changeDefault': {
        await firebase
          .firestore()
          .collection("users")
          .doc(uid)
          .update({
            'settings.defaultBook': item.id,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp()
          })
        result.status = 1
        result.message = 'Update Books Success'
        break
      }
      case 'renameBook': {
        await firebase
          .firestore()
          .collection("bookLists")
          .doc(item.id)
          .update({
            name: item.renameBook.split(/ /g).map((val: any) => val[0].toUpperCase() + val.slice(1)).join(' '),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp()
          })
        result.status = 1
        result.message = 'Update Books Success'
        break
      }
      case 'shareBook': {
        result.status = 0
        result.message = 'User Email Not Found'
        const findUser = await firebase
          .firestore()
          .collection("users")
          .where('email', '==', item.shareEmailAddress)
          .limit(1)
          .get()
        // console.log(findUser.docs)
        if (findUser.docs.length) {
          const shareUser: any = findUser.docs[0]
          // console.log(shareUser.id)
          const shareUserData = shareUser.data()
          const hasAccessData: any = {
            uid: shareUser.id,
            email: shareUserData.email,
            name: shareUserData.name,
            grantedAt: new Date(),
          }
          // console.log(hasAccessData)
          // check is already shared
          const checkSharing: any = await firebase
            .firestore()
            .collection("bookLists")
            .doc(item.id)
            .get()

          const checkSharingData: any = checkSharing.data()
          console.log(checkSharingData)
          console.log(shareUser.id)
          if (checkSharingData.hasAccess.filter( (hasAccessData: any) => hasAccessData == shareUser.id).length) {
            result.status = 0
            result.message = 'This book already shared to ' + shareUserData.email
          } else {
            await firebase
              .firestore()
              .collection("bookLists")
              .doc(item.id)
              .update({
                hasAccess: firebase.firestore.FieldValue.arrayUnion(shareUser.id),
                hasAccessData: firebase.firestore.FieldValue.arrayUnion(hasAccessData),
                updatedAt: firebase.firestore.FieldValue.serverTimestamp()
              })
            result.status = 1
            result.message = 'Book Share To ' 
            if (shareUserData.name) result.message += shareUserData.name + '- ' 
            result.message += item.shareEmailAddress 
          }
        }
        break
      }
      case 'stopShare': {
        result.status = 0
        result.message = 'Stop Share Failed'
        // console.log(hasAccessData)
        // check is already shared
        const checkSharing: any = await firebase
          .firestore()
          .collection("bookLists")
          .doc(item.id)
          .get()

        const checkSharingData: any = checkSharing.data()
        console.log(checkSharingData)
        const filterHasAccess = checkSharingData.hasAccess.filter( (hasAccessData: any) => hasAccessData == item.stopShareData.uid)
        console.log(filterHasAccess)
        console.log(item.stopShareData)
        if (filterHasAccess.length) {
          await firebase
            .firestore()
            .collection("bookLists")
            .doc(item.id)
            .update({
              hasAccess: firebase.firestore.FieldValue.arrayRemove(item.stopShareData.uid),
              hasAccessData: firebase.firestore.FieldValue.arrayRemove(item.stopShareData),
              updatedAt: firebase.firestore.FieldValue.serverTimestamp()
            })
          result.status = 1
          result.message = 'Book Stop Share To ' 
          if (item.stopShareData.name) result.message += item.stopShareData.name + '- ' 
          result.message += item.stopShareData.email 
        }
        break
      }
    }
    // Object.assign(result, {status: 1, message: 'Update Books Success'})
    return result
  }

  const selectedBookId = async () => {
    // const selectedBook: any = await localStorage.getItem('selectedBook')
    // console.log(JSON.parse(selectedBook).id)
    // return JSON.parse(selectedBook).id
    const selectedBook: any = await firebaseAuth().userData.value.settings.defaultBook
    return selectedBook
  }

  const getItem = async (cat: string, sortOptions?: any, filterOptions?: any) => {
    const bookId = await selectedBookId()
    const result = {status:0, message:'Get Data Failed', data:[]}

    let sortBy = "createdAt"
    let sortRule: any = "desc"
    if (sortOptions) {
      sortBy = sortOptions.by
      sortRule = sortOptions.rule
    }
    
    const date = new Date()
    let category = []
    let fromDate = new Date(date.getFullYear(), date.getMonth(), 1)
    let toDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    if (filterOptions) {
      fromDate = filterOptions.fromDate
      toDate = filterOptions.toDate
      if (filterOptions.category) category = filterOptions.category
    }

    // console.log(cat)
    // console.log(sortOptions)
    // console.log(filterOptions)
    // console.log(dateStart)
    // console.log(dateEnd)
    // console.log(bookId)
    
    let query
    if (cat === 'reports') {
      if (!category.length) {
        query = await firebase
          .firestore()
          .collection('bookLists')
          .doc(bookId)
          .collection('transactionLists')
          .where('bookId', '==', bookId)
          .where('transactionDate', '>=', fromDate)
          .where('transactionDate', '<=', toDate)
          .orderBy('transactionDate', 'desc')
          .orderBy(sortBy, sortRule)
          // .startAt(dateStart)
          // .endAt(dateEnd)
          .get()
      } else {
        query = await firebase
          .firestore()
          .collection('bookLists')
          .doc(bookId)
          .collection('transactionLists')
          .where('bookId', '==', bookId)
          .where('transactionDate', '>=', fromDate)
          .where('transactionDate', '<=', toDate)
          .where('category', 'in', category)
          .orderBy('transactionDate', 'desc')
          .orderBy(sortBy, sortRule)
          // .startAt(dateStart)
          // .endAt(dateEnd)
          .get()
      }
    } else {
      if (!category.length) {
        query = await firebase
          .firestore()
          .collection('bookLists')
          .doc(bookId)
          .collection('transactionLists')
          .where('bookId', '==', bookId)
          .where('dataType', '==', cat)
          .where('transactionDate', '>=', fromDate)
          .where('transactionDate', '<=', toDate)
          .orderBy('transactionDate', 'desc')
          .orderBy(sortBy, sortRule)
          .get()
      } else {
        query = await firebase
          .firestore()
          .collection('bookLists')
          .doc(bookId)
          .collection('transactionLists')
          .where('bookId', '==', bookId)
          .where('dataType', '==', cat)
          .where('transactionDate', '>=', fromDate)
          .where('transactionDate', '<=', toDate)
          .where('category', 'in', category)
          .orderBy('transactionDate', 'desc')
          .orderBy(sortBy, sortRule)
          .get()
      }
    }
  
    // console.log(`Get ${cat} Items`)
    if (query.docs) {
      result.status = 1
      result.message = 'Get Data Success'
      Object.assign(result.data, query.docs)
    }
    // console.log(result)
    return result
  }

  const addNewItem = async (cat: string, docId: string, data: object) => {
    const bookId = await selectedBookId()

    // console.log(data)
    // console.log(bookId)
    // console.log(docId)
    // console.log(cat)

    const result  = await firebase
      .firestore()
      .collection('bookLists')
      .doc(bookId)
      .collection('transactionLists')
      .add({
        bookId: bookId,
        ...data,
        createdBy: currentUserData,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })

    // console.log(`Add New ${cat} Item ID: ${docId}`)
    console.log(`Add New ${cat} Item ID: ${result.id}`)
    // console.log(result)
    return result
  }

  const modifyItem = async (cat: string, docId: string, data: object) => {
    const bookId = await selectedBookId()
    const result  = await firebase
      .firestore()
      .collection('bookLists')
      .doc(bookId)
      .collection('transactionLists')
      .doc(docId)
      .update({
        ...data,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })

    console.log(`Update ${cat} Item ID: ${docId}`)
    // console.log(result)
    return result
  }

  const deleteItem = async (cat: string, docId: string) => {
    const bookId = await selectedBookId()
    const result  = await firebase
      .firestore()
      .collection('bookLists')
      .doc(bookId)
      .collection('transactionLists')
      .doc(docId)
      .delete()

    console.log(`Delete ${cat} Item ID: ${docId}`)
    // console.log(result)
    return result
  }

  const updateCategoryList = async (data: any) => {
    // console.log(data)
    // console.log('updateCategoryList')
    const result  = await firebase
      .firestore()
      .collection('bookLists')
      .doc(data.id)
      .update({
        'settings.categories': data.settings.categories,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })

    // console.log(result)
    return result
  }

  return {
    // FUNCTIONS
    getAccountSettings,
    getBookLists,
    createBooks,
    updateBookDetail,
    selectedBookId,
    getItem,
    addNewItem,
    modifyItem,
    deleteItem,
    updateCategoryList
  }
}